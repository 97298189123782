import { useRef, useState, useMemo } from "react";
import * as THREE from "three";
import { useFrame, extend } from "@react-three/fiber";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import robotoFont from "assets/font-outfit-normal-500.json";
// import robotoFont from "three/examples/fonts/helvetiker_regular.typeface.json";
import { Line } from '@react-three/drei';

extend({ TextGeometry });

function InteractiveCylinder({ textHeight, setShowLabel, showLabel }) {
  // const handleClick = () => alert("Cylinder clicked!");

  return (
    <mesh
     onPointerEnter={() => setShowLabel(true)} 
      onPointerLeave={() => setShowLabel(false)}
      onClick={() => setShowLabel(!showLabel)}
    position={[0, 0, textHeight / 2]} rotation={[Math.PI / 2, 0, 0]}>
      <cylinderGeometry args={[0.01, 0.01, textHeight*2, 32]} />
      <meshStandardMaterial color="blue" />
    </mesh>
  );
}

function Label({ position, title, pressure, temperature, flow, textHeight }) {
  const labelGroupRef = useRef();

  // Use a font loader to load the font once
  const font = new FontLoader().parse(robotoFont);
  // const font = useMemo(() => {
  //   const loader = new FontLoader();
  //   return loader.parse(require("/assets/font-outfit-normal-500.json"));
  // }, []);

  // Make the entire group (text + background) face the camera
  useFrame(({ camera }) => {
    if (labelGroupRef.current) {
      labelGroupRef.current.quaternion.copy(camera.quaternion);
    }
  });

  const createRoundedRectShape = (width, height, radius) => {
    const shape = new THREE.Shape();
    shape.moveTo(-width / 2 + radius, height / 2); // Move to the top-left corner
    shape.lineTo(width / 2 - radius, height / 2); // Top edge
    shape.quadraticCurveTo(width / 2, height / 2, width / 2, height / 2 - radius); // Top-right corner
    shape.lineTo(width / 2, -height / 2 + radius); // Right edge
    shape.quadraticCurveTo(width / 2, -height / 2, width / 2 - radius, -height / 2); // Bottom-right corner
    shape.lineTo(-width / 2 + radius, -height / 2); // Bottom edge
    shape.quadraticCurveTo(-width / 2, -height / 2, -width / 2, -height / 2 + radius); // Bottom-left corner
    shape.lineTo(-width / 2, height / 2 - radius); // Left edge
    shape.quadraticCurveTo(-width / 2, height / 2, -width / 2 + radius, height / 2); // Top-left corner
    shape.closePath();
    return shape;
  };

  // Rounded rectangle mesh geometry
  const roundedRectGeometry = new THREE.ShapeGeometry(createRoundedRectShape(2, 0.9, 0.1)); // Customize the width, height, and radius

  return (
    <group ref={labelGroupRef} position={position}>
      {/* Card background */}
      <mesh position={[0.8, -0.18, textHeight - 0.1]} geometry={roundedRectGeometry}>
        <meshStandardMaterial color="white" />
      </mesh>

      {/* Text */}
      <mesh position={[0, 0, textHeight]}>
        <textGeometry
          args={[
            `Name: ${title}\nPresion: ${pressure}\nTemperatura: ${temperature}\nFlujo: ${flow}`,
            { font, size: 0.1, depth: 0.01 },
          ]}
        />
        <meshStandardMaterial color="black" />
      </mesh>
    </group>
  );
}



function PointerWithLabel({ coordX, coordY, title, pressure, temperature, flow }) {
  const textHeight = 2;
  const [showLabel, setShowLabel] = useState(false);
  <Line
  points={new THREE.CircleGeometry(0.5, 32).vertices} // Adjust the radius and segments as needed
  color="blue"
  lineWidth={1}
/>
  return (
    <group position={[coordX, coordY, 0.25]}>
      {/* Overlapping circles */}
      {/* <mesh>
        <circleGeometry args={[0.2, 32]} />
        <meshStandardMaterial color="blue" />
      </mesh> */}
      <mesh position={[0, 0, 0.01]}>
        <circleGeometry args={[0.1, 32]} />
        <lineBasicMaterial color="blue" />
        </mesh>

      {/* Cylinder pointing up */}
      <InteractiveCylinder textHeight={textHeight} 
      showLabel={showLabel}
      setShowLabel={setShowLabel}
      />

      {showLabel &&
      <Label
      position={[-0.1, 1.5, textHeight]}
      title={title}
      pressure={pressure}
      temperature={temperature}
      flow={flow}
      textHeight={textHeight}
      />
    }
    </group>
  );
}

export default PointerWithLabel;